body {
  margin: 0;
  font-family: "Poppins", 'Source Code Pro', 'Plus Jakarta Sans', 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overscroll-behavior: contain; */
  overscroll-behavior: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* width */
::-webkit-scrollbar {
	width: 3px;
  border-radius: 1.5rem;
  overflow: hidden;
}

/* Track */
::-webkit-scrollbar-track {
  background: #758195;
	border-radius: 1.5rem;
  overflow: hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #B3B8C4;
  border-radius: 1.5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #B3B8C4;
}