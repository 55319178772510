.icons {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.iconWrapper {
		width: 100%;
		height: 100%;
		position: relative;

		.iconContainer {
			transition: all 500ms linear;
			filter: grayscale(1);
			opacity: 0.05;

			@media screen and (min-width: 1200px) {
				transition: all 300ms linear;
			}

			svg {
				height: 0.6rem;
				// height: 2rem;
				width: auto;
				transition: all 300ms linear;

				@media screen and (min-width: 730px) {
					height: 0.9rem;
					// height: 3rem;
				}
				@media screen and (min-width: 1000px) {
					height: 0.8rem;
					// height: 2.8rem;
				}
				@media screen and (min-width: 1400px) {
					height: 1rem;
					// height: 3.5rem;
				}
				@media screen and (min-width: 1700px) {
					height: 1.2rem;
					// height: 3.7rem;
				}
			}
		}
	}

	// @media (hover: hover) {
	.active {
		filter: grayscale(0) !important;
		opacity: 1 !important;
		transform: scale(1.2);
	}
	// }
}
