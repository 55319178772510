.container {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: radial-gradient(
		99.97% 99.97% at 50% 0%,
		var(--color-surface-Light, #22272b) 0%,
		#1d2124 34.01%,
		var(--color-surface-subtle, #101214) 72.6%,
		var(--color-background-default, #0e0f11) 100%
	);
    background: #101214;
    overflow: hidden;
	position: relative;
	padding: 0 1.5rem;

	@media screen and (min-width: 730px) {
		padding: 0 7.5rem;
	}
	@media screen and (min-width: 1000px) {
		padding: 0 6.5rem;
	}
	@media screen and (min-width: 1400px) {
		padding: 0 16.5rem;
	}
	@media screen and (min-width: 1700px) {
		padding: 0 29.5rem;
	}

	.contentWrapper {
		pointer-events: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		color: #cacaca;
		background: #101214;
        border: 1.5rem;
		z-index: 3;

		.title {
			font-family: 'Plus Jakarta Sans';
			color: #dee4ea;
			// background: #101214;
			font-weight: 500;
			font-size: 2.25rem;
			line-height: 3rem;

			@media screen and (min-width: 730px) {
				font-size: 3.25rem;
				line-height: 3.9rem;
			}
			@media screen and (min-width: 1000px) {
				font-size: 4rem;
				line-height: 5rem;
			}
			@media screen and (min-width: 1400px) {
				font-size: 4.5rem;
				line-height: 6rem;
			}

			// span {
			// 	background: linear-gradient(
			// 		90deg,
			// 		#56c1ff 24.32%,
			// 		#ffce1b 76.77%
			// 	);
			// 	background-clip: text;
			// 	-webkit-background-clip: text;
            //     -webkit-text-fill-color: transparent;
			// }
		}

		.description {
			font-family: 'Inter';
			color: #9fadbc;
			// background: #101214;
			font-weight: 300;
			margin: 1.2rem 0 3.75rem 0;
			font-size: 1rem;
			line-height: 1.5rem;

			@media screen and (min-width: 730px) {
				margin: 1.2rem 0 3.75rem 0;
				font-size: 1.125rem;
				line-height: 1.75rem;
			}
			@media screen and (min-width: 1000px) {
				font-size: 1.25rem;
				line-height: 1.875rem;
			}
			@media screen and (min-width: 1400px) {
				font-size: 1.5rem;
				line-height: 2.25rem;
			}
		}

		button {
			width: auto;
			overflow: hidden;
			position: relative;
			display: flex;
			align-items: center;
			gap: 0.5rem;
			font-family: 'Plus Jakarta Sans';
			pointer-events: all;
            transition: 0.3s ease-out;
			background: #ffce1b;
			color: #0e0f11;
			font-weight: 600;
			z-index: 2;
			padding: 0.75rem 1.25rem 0.75rem 1rem;
			font-size: 1rem;
			border-radius: 0.75rem;

			@media screen and (min-width: 1200px) {
				background: #ebecef;
			}
		}

		button svg {
			position: relative;
			z-index: 3;
			height: 1.2rem;

			@media screen and (min-width: 730px) {
			}
			@media screen and (min-width: 1000px) {
				height: 1.2rem;
			}
		}

		button p {
			position: relative;
			z-index: 3;
		}

		// button::before {
		// 	background: #ffce1b;
		// 	transition: 0.3s ease-out;
		// 	position: absolute;
		// 	content: '';
		// 	top: 0;
		// 	left: 0;
		// 	right: 0;
		// 	height: 100%;
		// 	width: 100%;
		// 	z-index: 1;

		// 	@media screen and (min-width: 1400px) {
		// 		height: 0;
		// 	}
		// }

		button:hover::before {
			height: 100%;
		}

        button:hover{
            background: #ffce1b;
        }
	}
}
