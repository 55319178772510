:root {
	--nav_margin: 1rem;
	--nav_padding: 1rem;
	--page_margin: 1rem;
	--section_vertical_padding: 3rem;

	@media screen and (min-width: 730px) {
		--nav_margin: 1.2rem;
		--page_margin: 3rem;
		--section_vertical_padding: 4rem;
	}

	@media screen and (min-width: 1000px) {
		--nav_margin: 2.2rem;
		--page_margin: 4rem;
		--section_vertical_padding: 5.5rem;
	}

	@media screen and (min-width: 1400px) {
		--nav_margin: 2rem;
		--page_margin: 10.5rem;
		--section_vertical_padding: 7.5rem;
	}

	@media screen and (min-width: 1700px) {
		--nav_margin: 1rem;
		--page_margin: 12.5rem;
		--section_vertical_padding: 7rem;
	}

	--hero_bg_gradient: linear-gradient(11deg, #304E5D 7.91%, #131B1F 94.59%);
	--hero_bg_solid: #22333C;
}

* {
	// transition: all 300ms linear; //removed to avoid flickering in framer motion
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: transparent;
	scroll-behavior: smooth;
}

body {
	width: 100%;
	line-height: normal;
	overflow-x: hidden;
	max-width: 2500px;
}

a {
	text-decoration: none;
}

img {
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
